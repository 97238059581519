body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
/* .ant-spin-container {
  min-height: 100px;
} */
.spinner-height .ant-spin-container {
  min-height: auto;
  display: flex;
  align-items: center;
}
.spinner-height .ant-spin-container .file-upload-tick {
  margin-right: 10px;
}
.heading-two {
  font-size: 26px;
  color: #a00202;
  line-height: 30px;
  font-weight: 700;
}
.heading-two-light {
  font-size: 26px;
  color: #a00202;
  line-height: 30px;
  font-weight: 400;
  letter-spacing: 1px;
}
.key-feature-heading .heading-two {
  font-weight: 700;
}
.remove-btn {
  width: auto;
}
.b-b {
  width: 70%;
  height: 1px;
  margin: 0 auto;
  background-color: #ececec;
  margin-top: 2rem;
}
/****** Theme Style Start ********/
.ant-layout,
.ant-layout-footer {
  background-color: transparent;
  padding: 0;
}
.ant-layout-header {
  color: rgb(0 0 0 / 85%);
  background: transparent;
  padding: 0;
  height: auto;
}
.heading-two-light {
  margin: 0 0 5px;
}
.key-feature-heading {
  text-align: center;
}
/****** Theme Style End ********/
.header-sec {
  padding: 25px 0;
}
.header-advertise img {
  width: 100%;
}
.header-left {
  display: flex;
  align-items: center;
  height: 100%;
}
.header-left h1 {
  font-size: 26px;
  line-height: 30px;
  margin: 0;
  color: #209f8f;
  margin-bottom: 1rem;
}
.logout-btn {
  width: max-content;
}
.btn-group a {
  border: 1px solid #209f8f;
  color: #209f8f;
  margin-right: 10px;
  border-radius: 30px;
  padding: 2px 20px;
}
.btn-group a:hover {
  border: 1px solid #a00202;
  color: #a00202;
}
.header-advertise img {
  border-radius: 6px;
}
.footer-sec {
  background-color: #209f8f;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.footer-sec p {
  margin: 0;
  color: #ffffff;
}
.banner-sec img {
  width: 100%;
}
.login-sec {
  padding: 40px 0;
}
.client-info {
  padding-right: 4rem;
}
.client-info p {
  color: #7d7d7d;
  font-size: 18px;
}
.login-form-sec {
  padding-left: 2rem;
  border-left: 1px solid #ececec;
}
.login-form-sec p {
  color: #7d7d7d;
  font-size: 18px;
}
.key-feature-box-sec {
  display: flex;
}
.key-feature-box {
  width: 33.33%;
  text-align: center;
  padding: 4rem 0;
  margin-bottom: 30px;
}
.key-feature-box span.key-feature-icon {
  border: 1px solid #209f8f;
  padding: 1px;
  border-radius: 100%;
  width: 70px;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto 20px;
}
/* .key-feature-box:hover svg {
  color: #209f8f;
  font-size: 32px;
} */
.key-feature-box span.key-feature-icon svg:not(:root) {
  fill: #209f8f;
  width: 55%;
}
.key-feature-box h3 {
  color: #209f8f;
  margin-bottom: 15px;
  font-size: 24px;
}
.key-feature-box p {
  color: #209f8f;
  padding: 0 20px;
}
/*** Hover ***/
.key-feature-box:hover {
  background-color: #209f8f;
  border-radius: 10px;
}
.key-feature-box:hover span.key-feature-icon {
  border: 1px solid #ffffff;
  padding: 1px;
  border-radius: 100%;
  width: 70px;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto 20px;
}
.key-feature-box:hover svg {
  fill: #ffffff !important;
}
.key-feature-box:hover h3 {
  color: #ffffff;
  margin-bottom: 15px;
  font-size: 24px;
}
.key-feature-box:hover p {
  color: #ffffff;
  padding: 0 20px;
}
/*** Hover ***/
.key-feature-hover {
  background-color: #209f8f;
  border-radius: 10px;
}

.key-feature-hover span.key-feature-icon {
  border: 1px solid #ffffff;
  padding: 1px;
  border-radius: 100%;
  width: 70px;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto 20px;
}
.key-feature-hover .anticon {
  color: #ffffff;
  font-size: 32px;
}
.key-feature-hover h3 {
  color: #ffffff;
  margin-bottom: 15px;
  font-size: 24px;
}
.key-feature-hover p {
  color: #ffffff;
  padding: 0 20px;
}
.form-style .ant-input {
  border-radius: 10px;
  height: auto;
  padding: 10px 15px;
  background-color: #e7f8ff;
}
.form-style .ant-input-affix-wrapper {
  border-radius: 10px;
  height: auto;
  padding: 10px 15px;
  background-color: #e7f8ff;
}
.form-style .mb-0 .ant-input {
  background-color: #ececec;
}
.form-style .or {
  display: flex;
  justify-content: center;
  padding: 12px 0;
}
.form-btns {
  border-right: 1px solid #ececec;
}
.form-btns .ant-btn-primary {
  background-color: #f26522;
  border-radius: 30px;
  color: #ffffff;
  border: 0px;
  display: block;
  margin-bottom: 10px;
}
.form-btns .login-form-forgot {
  color: #0642c8;
}
.form-btns .ant-form-item {
  margin: 0;
}
.remember-checkbox {
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: flex-end;
}
.remember-checkbox span {
  color: #7d7d7d;
}
.remember-checkbox .ant-form-item {
  margin: 0;
}
.menu {
  display: flex;
  justify-content: center;
  background-color: #209f8f;
  align-items: center;
  height: 60px;
}
.menu .ant-menu-horizontal {
  background: transparent;
  border: 0px;
  display: flex;
  align-items: center;
  /* width: 100%; */
}
.menu .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item a {
  color: #ffffff;
  font-size: 18px;
  border: 0px;
}
.menu .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected {
  font-weight: 500;
  border: 0;
}
.menu .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover,
.menu .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:focus {
  border: 0px;
}
.menu
  .ant-menu-horizontal:not(.ant-menu-dark)
  > .ant-menu-item-selected::after {
  content: "";
  width: 40%;
  height: 2px;
  background-color: #f26522;
  position: absolute;
  bottom: 6px;
  left: 50%;
  margin-left: -20%;
}
.menu .language {
  color: #ffffff;
  background-color: transparent;
  /* border-radius: 30px; */
  border: 0px;
  padding: 0px 15px;
  height: 40px;
}
.menu .language:hover .language {
  color: #a00202;
}
.menu .language .ant-menu-submenu-title {
  padding: 7px 20px;
  line-height: 1px;
}
.menu .ant-menu-horizontal > .ant-menu-item {
  border: 0px;
}
.menu .ant-menu-horizontal > .ant-menu-item a::before {
  display: none;
}
/* .footer-contact {
} */
.contact-info-sec {
  padding: 45px 0px;
  border: 1px solid #e5e9eb;
  display: flex;
  align-items: center;
  margin-bottom: 4rem;
}
.footer-title {
  color: #209f8f;
  text-align: center;
  font-size: 32px;
  font-weight: 400;
  padding-bottom: 1px;
}
.footer-info-text {
  display: flex;
}
.footer-logo {
  width: 25%;
  text-align: center;
  position: relative;
}
.footer-logo img {
  max-width: 170px;
  margin-bottom: 1rem;
}
.footer-logo p {
  margin: 0;
  color: #2e2e2e;
  line-height: 20px;
  font-size: 18px;
}
.footer-logo:after {
  content: "";
  background-color: #b7b7b7;
  width: 2px;
  height: 135px;
  position: absolute;
  right: 0;
  top: 0;
  margin-top: -34px;
}
.footer-info-text {
  width: 75%;
}
.footer-info-box {
  width: 33.33%;
  text-align: center;
}

/* .footer-info-box .anticon {
  font-size: 28px;
  margin-bottom: 10px;
} */
.footer-info-box h4 {
  font-size: 18px;
  color: #2e2e2e;
  margin-bottom: 0px;
  position: relative;
}
.footer-info-box h4:nth-child(1),
.footer-info-box h4:nth-child(2) {
  margin-bottom: 10px;
}
.footer-info-box h4 span {
  position: relative;
}
.footer-info-box h4 span:after {
  content: "";
  height: 2px;
  width: 30px;
  bottom: -5px;
  left: 50%;
  margin-left: -15px;
  background-color: #b7b7b7;
  position: absolute;
}
.footer-info-box p {
  color: #686767;
  font-size: 18px;
  line-height: 20px;
  margin: 0;
}
/* .footer-info-box img {
  max-width: 26px;
  margin-bottom: 1rem;
} */
.contact-us-icon svg {
  fill: #757575;
  width: 24px;
  align-items: center;
  margin-bottom: 1rem;
}
.Email-main-sec {
  padding: 40px 0;
}
.email-company-profile-sec {
  padding-top: 3rem;
}
.email-company-profile-sec ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.email-company-profile-sec ul li {
  border-bottom: 1px solid #ffffff;
}
.email-company-profile {
  background-color: #efefef;
  padding: 15px 8px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.email-company-profile .ant-avatar-image {
  margin-right: 5px;
}
.email-company-profile span {
  color: #4f4f4f;
}
.ant-tabs-tab {
  margin-right: 0.8rem;
}
.email-tabs-sec .ant-tabs-nav-list .ant-tabs-tab {
  border: 1px solid #209f8f;
  color: #209f8f;
  border-radius: 30px;
  padding: 3px 20px;
}
.email-tabs-sec .ant-tabs-nav {
  margin-bottom: 18px;
}
.email-tabs-sec .ant-tabs-nav-list .ant-tabs-tab-active {
  border: 1px solid #209f8f;
  background-color: #209f8f;
  color: #ffffff;
  border-radius: 30px;
  padding: 3px 20px;
}
.email-tabs-sec .ant-tabs-nav-list .ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #ffffff;
  font-weight: 300;
}
.email-tabs-sec .ant-tabs-top > .ant-tabs-nav::before {
  border: 0px;
}
.email-tabs-sec .ant-tabs-ink-bar {
  background-color: transparent;
}
.email-history-sec {
  padding: 21px 25px 10px;
  background-color: #fbfbfb;
  margin-bottom: 1rem;
  width: 100%;
  float: left;
}
.email-history-header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  float: left;
}
.email-history-subject p {
  color: #6e6e6e;
  font-size: 16px;
}
.email-history-header {
  border-bottom: 1px solid #e1e1e1;
}
.email-history-subject p span {
  color: #6e6e6e;
  font-weight: 500;
}
.email-history-date p {
  color: #6e6e6e;
  font-size: 16px;
}
.email-history-view {
  display: flex;
  padding: 15px 0;
  width: 100%;
  float: left;
}
.email-history-view-text {
  padding-left: 2rem;
  padding-top: 1rem;
}
.email-history-user-info {
  margin-bottom: 1rem;
}
.email-history-user-info h4 {
  margin: 0;
  line-height: 18px;
  color: #6e6e6e;
}
.email-history-user-info p {
  color: #6e6e6e;
}
.email-history-body {
  margin-bottom: 4rem;
}
.email-history-body p {
  margin: 0;
  line-height: 18px;
  color: #6e6e6e;
}
.email-history-bottom p {
  color: #6e6e6e;
}
.email-history-regars {
  margin-bottom: 4rem;
}
.email-history-regars p {
  margin: 0;
  line-height: 18px;
  color: #6e6e6e;
}
.email-attachment-text {
  border-top: 1px solid #bfbfbf;
  border-bottom: 1px solid #bfbfbf;
  padding: 2rem 6rem;
  width: 100%;
  float: left;
}
.email-attachment-text p {
  color: #6e6e6e;
  margin: 0;
}
.email-reply-sec {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 0px;
  width: 100%;
  float: left;
}
.email-history-attachment {
  display: flex;
  align-items: center;
}
.email-history-attachment img {
  margin-right: 15px;
}
.email-history-attachment span {
  color: #6e6e6e;
  font-size: 16px;
  display: flex;
  align-items: center;
}
.email-history-attachment span a {
  margin: 0 15px;
}
.login-form-button {
  background-color: #f26522;
  border: 0px;
  border-radius: 30px;
}
.login-form-button:hover {
  background-color: #1282bb;
}
.mar-client-tab {
  margin-top: 37px;
  margin-bottom: 37px;
}
.tab-border {
  border: 1px solid;
}
.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab:not(:last-of-type) {
  margin-right: 0px;
}
.align-center {
  text-align: center;
}
.client-information-container {
  width: 38%;
  margin: auto;
}
.client-information-setting span {
  align-items: center;
  /* width: 50%; */
  /* margin: auto; */
}
.information-area ul {
  margin: 0;
  padding: 0;
  color: #686868;
  padding-top: 30px;
  font-size: 16px;
  font-weight: 450;
}

.info-heading {
  color: #6e0202;
  font-size: 18px;
  font-weight: 700;
}
.padding-tb {
  padding-top: 36px;
  padding-bottom: 36px;
}
.client-profile-tabs .ant-tabs-nav {
  margin: 0;
}
.client-profile-tabs .ant-tabs-nav-list .ant-tabs-tab {
  border: 1px solid #eaeaea;
  color: #858585;
  background-color: transparent;
}
.client-profile-tabs .ant-tabs-nav-list .ant-tabs-tab-active {
  background-color: transparent;
  border: 1px solid #bfbfbf;
  color: #d0cbcb;
}
.client-profile-tabs .ant-tabs-nav-list .ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #858585;
}
.employer-form-input .ant-input:placeholder-shown {
  border: 1px solid #d8d5d5;
  border-radius: 11px;
  padding: 8px;
}
.form-container {
  width: 100%;
  margin: auto;
  position: relative;
}

.form-btn .ant-btn-block {
  width: 10%;
}
.form-btn .ant-btn-dashed {
  color: white;
  background-color: orange;
}
.ant-form-item-control-input-content .ant-row .ant-form-item .form-btn {
  position: absolute;
  top: 2rem;
  left: 0;
  width: 100%;
}
.save-btn {
  float: right;
  padding: 0px 27px;
  margin: 31px 0px;
}
.ant-form-vertical .ant-form-item-label > label {
  color: #868383;
  margin-left: 12px;
  font-weight: 450;
}
.ant-picker {
  border: 1px solid #d8d5d5;
  border-radius: 8px;
}
.ant-picker-input > input {
  color: rgba(0, 0, 0, 0.85) !important;
}
.ant-picker svg,
.ant-select-arrow .anticon {
  color: #209f8f;
}
.ant-picker-input > input {
  color: #c5c5c5;
}
.forms-styling-tabs {
  border: 1px solid #d0cbcb;
  background-color: #f6f6f6;
  /* margin-bottom: 60px; */
  margin-bottom: 28px;
}
.forms-styling-tabs .ant-space {
  width: 80%;
  margin: auto;
  padding-top: 6rem;
}
.form-add-btn {
  position: absolute;
  top: 31px;
  left: 8rem;
  z-index: 10;
}
.information-area-bg {
  background-color: #f6f6f6;
  border: 1px solid #d0cbcb;
}
.container-family-header {
  width: 90%;
  margin: auto;
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
  margin-top: 30px;
}
.container-family-header button {
  width: 10%;
}
.container-family-header .ant-radio-button-wrapper:first-child {
  margin-right: 20px;
}
.container-family-header .ant-radio-button-wrapper {
  border-radius: 6px;
  border: 1px solid #bfbfbf;
  color: #bfbfbf;
}
.container-family-header .ant-radio-button-wrapper:not(:first-child)::before {
  display: none;
}
.container-family-header .ant-radio-button-wrapper-checked,
.container-family-header
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):first-child,
.container-family-header
  .ant-radio-button-wrapper-checked:not([class*=" ant-radio-button-wrapper-disabled"]).ant-radio-button-wrapper:first-childs {
  border: 1px solid #828283;
  color: #828283;
}
/* .information-area .ant-tabs > ul > li > span{
  color: red;
} */

.remove-button {
  cursor: pointer;
  background-color: #f52b2b;
  color: white;
  display: flex;
  width: 10%;
  padding: 7px 14px;
  border-radius: 44px;
  font-weight: 400;
  margin: 35px 0px;
}
.remove-button p {
  margin: 0;
  padding-right: 5px;
}
.remove-button:hover {
  background-color: #1890ff;
  color: white;
}
.visa-status-header {
  height: 100px;
  display: flex;
  /* justify-content: space-around; */
  margin-top: 83px;
}
.status-header-left {
  width: 100%;
  background-color: #f3f3f3;
  border-radius: 7px 0 0 7px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 40px;
}
.status-header-left h2 {
  color: #858585;
  margin: 0;
}
.status-header-left p {
  color: #858585;
  font-weight: 400;
  font-size: 18px;
  margin: 0;
  margin-top: 6px;
}
.status-header-right {
  width: 30%;
  font-size: 12px;
  background-color: #a0d9ee;
  border-radius: 0 7px 7px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 15px;
  text-align: center;
}
.status-header-right-admission {
  width: 30%;
  font-size: 12px;
  background-color: #a0d9ee;
  border-radius: 0 7px 7px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 60px;
  text-align: center;
}
.status-header-right h2 {
  color: #ffffff;
  margin: 0;
}
.status-header-right-admission h2 {
  color: #ffffff;
  margin: 0;
}
.event-visa-btn {
  background: #f6752f;
  border: 0;
  border-radius: 5px;
  color: white;
  font-weight: 400;
  padding: 3px 17px;
}
.visa-status-body {
  background-color: #f3f3f3;
  margin-top: 40px;
  border-radius: 5px;
  padding: 10px 30px 30px 30px;
}
.visa-status-body button {
  margin-bottom: 7px;
}
.visa-status-body-data {
  background-color: white;
  width: 100%;
  margin: auto;
  border-radius: 5px;
  padding: 30px;
}
.ant-badge-status-dot {
  border-radius: 0;
  width: 16px;
  height: 16px;
  margin-right: 30px;
}
.status-header-signed {
  background-color: #fff568 !important;
}
.visa-body-text {
  justify-content: space-between;
  display: flex;
  max-width: 64%;
}
.visa-body-text span {
  color: #5c5c5c;
  font-weight: 450;
}
.visa-hide-btn {
  width: 100%;
  justify-content: flex-end;
  display: flex;
}
.document-button {
  background-color: transparent;
  border: 1px solid #209f8f;
  border-radius: 30px;
}
.checklist-tbl {
  text-align: center;
  font-size: 24px;
  padding: 10px 0 12px;
  background: #209f8f;
  margin: 0;
  color: #ffffff;
}
.document-button:hover,
.document-button:active,
.document-button:focus {
  background-color: #209f8f;
  color: #ffffff !important;
  border: 1px solid #209f8f;
}
.document-button:hover {
  background-color: #209f8f;
  color: white !important;
}
.site-text {
  color: #209f8f !important;
}
.email-editor-toolbar {
  padding: 1.1rem 0 0;
}
.email-editor-toolbar
  .ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector {
  border-radius: 6px;
  background-color: #efefef;
}
.email-editor-toolbar .ant-input {
  border-radius: 6px;
}
.editor-wrapper {
  border: 1px solid #d9d9d9;
  border-radius: 6px;
  margin-bottom: 1rem;
}
.editor-wrapper .bf-controlbar {
  background-color: #efefef;
}
.editor-wrapper .bf-content {
  height: 250px;
}
.file-uploader .ant-upload.ant-upload-drag {
  border: 1px solid #d9d9d9;
  border-radius: 6px;
  background-color: transparent;
  padding: 5rem;
  margin-bottom: 2rem;
}
.file-uploader .ant-upload-text {
  color: #209f8f !important;
}
.email-editor-btns {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.email-editor-btns .login-form-button:first-child {
  margin-right: 1rem;
}
.document-table .ant-table-thead > tr > th {
  color: #636363;
  background-color: f6f6f6;
  border: 0px;
}
.document-table .ant-table-tbody > tr > td {
  color: #6e6e6e;
  border-bottom: 0;
}
.table-row-light {
  background-color: #f7fdff;
}
.table-row-dark {
  background-color: #f6f6f6;
}
.document-table {
  border: 1px solid #dddddd;
}
.document-table .download-btn {
  border: 0px;
  background-color: transparent;
}
.document-table .download-btn.anticon {
  font-size: 18px;
  color: #404447;
}
.document-table .copy-link {
  border-radius: 30px;
}
.document-table .click-here {
  padding: 0;
}
/* .visa-form-main {

} */
.visa-form-main ul {
  display: flex;
}
.visa-form-main ul li {
  list-style-type: none;
}
.visa-form-main ul li span {
  padding-right: 10px;
}
.visa-form-main ul li a {
  color: #6e6e6e;
}
.visa-form-main .anticon {
  color: red;
  font-size: 14px;
}
.balnce-form-dark {
  display: flex;
  background-color: #209f8f;
  padding: 1rem 0;
  color: white;
  align-items: center;
}
.balnce-form-dark p {
  margin: 0px;
  font-size: 16px;
  word-break: break-all;
}
.balnce-form-light {
  display: flex;
  background-color: #ebebeb;
  padding: 1.2rem 0;
  align-items: center;
  margin: 0px;
}
.balnce-form-light .ant-checkbox-wrapper {
  color: #6e6e6e;
  font-size: 14px;
  font-weight: 500;
}
.balnce-form-light p {
  margin: 0px;
}
.top-bar p {
  color: #6e6e6e;
  font-size: 17px;
  font-weight: 500;
  padding: 0.7rem 0;
  margin: 0px;
}
.end-form {
  color: #6e6e6e;
  display: flex;
  height: 10rem;
  align-items: center;
  margin-left: 10rem;
}
.end-form p {
  margin-bottom: 3px;
  color: #6e6e6e;
  font-size: 16px;
  font-weight: 500;
}
.balnce-form-light .ant-checkbox {
  border: 1px solid #209f8f;
}
.video-modal .ant-modal-body {
  padding: 0px;
  border-radius: 12px;
}
.video-modal .ant-modal-close-x {
  background-color: rgb(185 76 23 / 0.9);
  border-radius: 100%;
  display: block;
  width: 34px;
  height: 34px;
  font-size: 12px;
  font-style: normal;
  line-height: 34px;
  text-align: center;
  text-transform: none;
  text-rendering: auto;
  position: absolute;
  z-index: 0;
  right: -20px;
  top: -19px;
  color: #fff;
}
.video-modal .ant-modal-close-x:hover {
  background-color: rgb(185 76 23 / 1);
}
.video-modal iframe {
  border-radius: 12px;
}
.video-modal .ant-modal-content {
  border-radius: 12px;
  background-color: transparent;
  box-shadow: none;
}

.profile-form {
  width: 316px;
  margin-left: -74px;
}
.profile-form .ant-picker {
  width: 316px !important;
  border-radius: 7px !important;
}
.profile-form
  .ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector {
  border-radius: 7px !important;
}
.profile-form .ant-input {
  border-radius: 7px;
}
.profile-form .ant-select {
  border-radius: 7px !important;
}
.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #209f8f;
  border-color: #209f8f;
}

.last-text-area {
  width: 312px;
  margin-left: -72px;
}

.member-name-btn .ant-btn-link {
  width: auto;
  border: 1px solid #bfbfbf;
  color: #bfbfbf;
  margin-right: 20px;
  border-radius: 6px;
}

.member-name-btn .ant-btn-link:hover,
.member-name-btn .ant-btn-link:focus {
  color: #209f8f;
  border: 1px solid #209f8f;
}

/* -----@Media 768px---- */

@media screen and (max-width: 768px) {
  .menu {
    display: block;
  }
  .header-advertise {
    margin-top: 18px;
  }
  .header-media {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .footer-logo img {
    max-width: 154px;
  }
}
.submit-access-btn {
  padding: 16px 50px 7px 0;
}
.update-employer-btn {
  padding: 0px 50px 0px 0;
}
.main-margin {
  margin-top: 50px;
}
.ant-spin-nested-loading > div > .ant-spin {
  max-height: 100%;
}
.add-document {
  margin-top: 20px;
  background: #f6f6f6;
  padding: 25px;
}
.checklist-upload {
  display: flex;
}
.checklist-upload .ant-upload-list-text {
  display: none;
}
.document-table-checklist .ant-table .ant-table-cell {
  width: 25%;
}
.file-upload-tick svg {
  color: #209f8f;
  font-size: 18px;
}
.calculate-amount {
  word-break: break-all;
  width: 80%;
}
.client-info-tab li {
  margin-bottom: 7px;
}
